<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto2 self-center">
            <div>
                <div slot="no-body" class="">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto2 self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto2">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto2 self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Recuperar contraseña</h4>
                                    <p>Ingresa tu correo para enviarte una nueva</p>
                                </div>
                                <input type="email" label-placeholder="Email" v-model="correo" class="w-full mb-8" />
                                <vs-button to="/login" class="px-4 w-full md:w-auto">Regresar</vs-button>
                                <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" @click="sendEmail()" :disabled="!validar">Recuperar contraseña</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      correo: ''
    }
  },
   computed:{
       emailState() {
      const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.correo);
      return this.correo == null || matches ? true:false
    },
       validar(){
           return this.emailState
       }
   },
  methods:{
      sendEmail(){
      let self=this
      var objEmail = {
        mail:this.correo
      }
      this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/PasswordRestablecida.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
            this.$router.push('/login');
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico ',
            
            position:'top-right'
          })
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>
